<template lang="pug">
  div#home
    .home
      .home-inner
        .hello
          h1 Lynn Kaminsky
          p Artist
</template>

<script>
export default {
  name: "Home",
  mounted() {
    document.getElementById("main").classList.remove("dark-bg");
    document.getElementById("main").classList.remove("dark-bg-mobile");
  }
};
</script>

<style scoped lang="less">
.home {
  .home-inner {
    margin: 0 auto;
  }
}

h1,
p {
  color: white;
  font-weight: 500;
}

p {
  font-size: 32px;
}

.hello {
  margin: 0 auto;
  height: 0;
  position: fixed;
  top: 44%;
  left: 50%;
  transform: translate(-50%, 50%);
  text-shadow: 1px 3px 16px var(--dark-bg-color);
  h1 {
    white-space: nowrap;
  }
}

@media (max-width: 767px) {
  p {
    font-size: 5vw;
  }

  .hello {
    margin: 28vh auto;
    height: auto;
    position: initial;
    top: unset;
    left: unset;
    transform: none;
  }
}
</style>
