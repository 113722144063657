<template lang="pug">
  div#app
    b-container.mx-0.px-0#main(fluid)
      #router-content
        transition(name="fade", mode="out-in", @beforeLeave="beforeLeave", @enter="enter", @afterEnter="afterEnter")
          router-view
      app-header
      app-footer
</template>

<script>
//import your components here
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
export default {
  name: "App",
  data() {
    return {
      prevHeight: 0
    };
  },
  components: {
    "app-header": Header,
    "app-footer": Footer
  },
  methods: {
    beforeLeave(element) {
      this.prevHeight = getComputedStyle(element).height;
    },
    enter(element) {
      const { height } = getComputedStyle(element);

      element.style.height = this.prevHeight;

      setTimeout(() => {
        element.style.height = height;

        var myElement = document.getElementById("main");
        console.log(myElement);
        var topPos = myElement.offsetTop;
        console.log(topPos);

        document.getElementById("main").scroll({
          top: topPos,
          left: 0,
          behavior: "smooth"
        });
      });
    },
    afterEnter(element) {
      element.style.height = "auto";
    }
  },
  mounted() {
    // document.documentElement.style.setProperty("--secondaryR",Math.floor(Math.random() * 255) + 1);
    // document.documentElement.style.setProperty("--secondaryG",Math.floor(Math.random() * 255) + 1);
    // document.documentElement.style.setProperty("--secondaryB",Math.floor(Math.random() * 255) + 1);
  }
};
</script>

<style lang="less">
@import "./assets/styles/fonts.css";
@import "./assets/styles/variables.less";
@import "./assets/styles/animations.css";
@import "./assets/styles/helpers.css";
@import "./assets/styles/custom.less";
@import "./assets/styles/mobile.less";
@import "./assets/styles/notouch.less";

html {
  margin-left: calc(100vw - 100%);
}

.fade-enter-active,
.fade-enter-active * {
  transition-duration: 1s !important;
  transition-timing-function: ease !important;
  visibility: visible !important;
}

.fade-leave-active,
.fade-leave-active * {
  transition-duration: 0.3s !important;
  transition-timing-function: ease !important;
  visibility: visible !important;
}

.fade-enter-active,
.fade-leave-active {
  transition-property: height;
  overflow: hidden;
  &:not(#home) > :first-child {
    transform: translateY(0%);
  }
}

.fade-enter,
.fade-leave-active {
  &:not(#home) > :first-child {
    transform: translateY(2.25%);
  }
}

.fade-enter-active,
.fade-leave-active,
.fade-enter-active *,
.fade-leave-active * {
  transition-property: opacity;
  opacity: 1 !important;
  visibility: visible !important;
}

.fade-enter,
.fade-leave-active,
.fade-enter *,
.fade-leave-active * {
  transition-property: opacity;
  opacity: 0 !important;
  visibility: hidden !important;
}

html,
body,
#app,
#main {
  height: 100%;
  min-height: 100%;
  color: var(--primary-color);
  transition: all 0.25s ease;
  font-family: var(--paragraphs);
  overflow-x: hidden;
  overflow-y: hidden;
}

/*#main.dark-bg {
  background: RGBA(var(--darkBG-R),var(--darkBG-G),var(--darkBG-B),0.98);
}*/

body {
  transition: all 0.5s ease;
  background-color: var(--dark-bg-color);
  background-image: url("assets/images/bg.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  width: 100%;
  height: 100%;
}

p,
th,
td {
  color: var(--primary-color);
  transition: all 0.5s ease;
  font-size: 16px;
}

p {
  color: var(--light-faded-color);
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 1.7;
  padding: 6px 0;
}

a:link,
a:visited,
a:hover,
a:active {
  color: var(--primary-color);
}

#app {
  font-family: var(--paragraphs);
  letter-spacing: 0.05rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--primary-color);
  transition: all 0.5s ease;
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: var(--headings);
    letter-spacing: normal;
  }
  h1 {
    font-size: 5.5rem;
  }
  .header {
    position: fixed;
    width: calc(100vw - var(--scrollbar-width));
    top: 0;
    background: linear-gradient(
      180deg,
      rgba(var(--darkBG-R), var(--darkBG-G), var(--darkBG-B), 1) 0%,
      rgba(var(--darkBG-R), var(--darkBG-G), var(--darkBG-B), 0) 100%
    );
  }
  .footer {
    font-size: x-small;
    width: calc(100vw - var(--scrollbar-width));
    padding-top: 18px;
    height: var(--footer-height);
    background: linear-gradient(
      0deg,
      rgba(var(--darkBG-R), var(--darkBG-G), var(--darkBG-B), 1) 0%,
      rgba(var(--darkBG-R), var(--darkBG-G), var(--darkBG-B), 0) 100%
    );
    p {
      padding-bottom: 8px;
      margin-bottom: 0;
      font-size: 11px;
    }
  }
  #main {
    overflow-y: scroll !important;
    &.dark-bg {
      background: var(--dark-bg-color);
      background: linear-gradient(
        270deg,
        rgba(var(--darkBG-R), var(--darkBG-G), var(--darkBG-B), 1)
          var(--scrollbar-width),
        rgba(var(--darkBG-R), var(--darkBG-G), var(--darkBG-B), 0)
          var(--scrollbar-width)
      );
    }
  }
  #router-content {
    width: 100vw;
    overflow-x: hidden;
    overflow-y: hidden;
    > div {
      display: initial;
      > div {
        padding: 0px var(--gutters);
      }
    }
  }
  .card {
    background-color: var(--card-color);
  }
}

#nav {
  font-family: var(--paragraphs);
  height: var(--nav-height);
  margin: 0;
  padding: 32px 0px 0px;
  transition: all 0.5s ease;

  a {
    position: relative;
    display: block;
    text-decoration: none;
    padding: 0px;
    margin: 0 1.5rem 0;
    transition: all 0.5s ease;
    &::before {
      position: absolute;
      content: "";
      width: 100%;
      height: var(--hr-width);
      top: 22px;
      left: 0;
      background: var(--primary-color);
      transform: scaleX(0);
      transform-origin: center;
      transition: all 0.5s ease;
    }
    &:hover::before,
    &.router-link-exact-active::before,
    &.router-link-active::before,
    &.router-link-active:hover::before {
      transform: scaleX(1);
      transform-origin: center;
    }
  }
}

.spinner-border {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  vertical-align: text-bottom;
  border: 0.175em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}

#media-item-modal {
  .modal-header {
    padding: 0 10px;
    z-index: 100;
    background: none !important;
    border: none;
    color: white;
    font-variant: all-small-caps;
    font-weight: 100;
    font-family: var(--headings);
    letter-spacing: 1px;
    opacity: 0.5;
  }
  .modal-body {
    padding: 0;
    > span {
      color: white;
      opacity: 0.5;
      display: block;
      width: 100%;
      text-align: left;
      padding: 10px 16px;
    }
  }
  .modal-body,
  .modal-content {
    background: transparent !important;
    max-height: 100%;
  }
  .modal-footer {
    display: none;
  }
  .modal-dialog {
    width: 100%;
    height: 100%;
    max-width: var(--max-width);
    margin: 0 auto;
  }
}

div#media-item-modal___BV_modal_backdrop_ {
  opacity: 0.9;
}

.enforce-max-width {
  max-width: var(--max-width) !important;
  margin: 0 auto;
}
</style>
