<template lang="pug">
  .header
    #nav
      nav
        transition(name="slide-fade", appear)
          b-nav(align='center')
            b-nav-item(to='/', exact, exact-active-class='active', class='logo heading') Artful Lynn
            b-nav-item(to='/about', exact-active-class='active') About
            b-nav-item(to='/gallery', exact-active-class='active') Gallery
            b-nav-item(to='/contact', exact-active-class='active') Contact
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      mode: "dark"
    };
  }
};
</script>

<style scoped lang="less">
.logo {
  position: absolute;
  left: 10px;
  top: 25px;
  font-size: 22px;
  letter-spacing: normal;

  a::before {
    display: none;
  }
}

@media (max-width: 767px) {
  .logo {
    left: 50%;
    top: 20px;
    transform: translateX(-50%);
    font-size: 5vw;
  }

  .nav-item:not(.logo) {
    margin-top: 30px;
  }
}
</style>
