<template lang="pug">
  .footer.fixed-bottom
    p
      span © Copyright 2023 Lynn Kaminsky
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      bgVariant: "light"
    };
  }
};
</script>

<style scoped lang="less">
p {
  color: var(--light-home-color);
}
</style>
